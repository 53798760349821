<template>
  <div class="order-box">
    <div class="content-right-wrapper">
      <div class="right-title">我的订单</div>
    </div>
    <div class="order-content">
      <div class="order-table">
        <div class="result-order-top">
          <ul class="result-order-wrap">
            <li v-for="(item) in orderTypeList" :key="item.id" @click="changeOrder(item)">
              <span :class="{ active: item.id === activeIdx }">{{ item.name }}</span>
            </li>
          </ul>
        </div>
      </div>
      <div class="product-title">
        <ol class="product-wrap">
          <li>
            <span>
              <div class="picker-time">
                <span>订单范围</span>
                <date-picker v-model="time" type="datetime" format="YYYY-MM-DD HH:mm:ss" :disabled-date="disabledDate"
                  placeholder="请输入" @input="handleSelect" @clear="handleClear" range>
                </date-picker>
                <!-- 实验 -->
                <!-- <date-picker v-model="value2" type="datetime" @input="handleSelect" :disabled-date="disabledDate"
                  @clear="handleClear" range placeholder="Select datetime range"></date-picker> -->
              </div>
            </span>
          </li>
          <li>
            <div class="search-box">
              <div class="search-inp">
                <input type="text" placeholder="搜商品名称、订单编号" v-model="large" @blur="getOrderList"
                  @keyup.enter="getOrderList" />
              </div>
              <div class="search-img">
                <span class="iconfont icon-search" @click="getOrderList">&nbsp;搜索</span>
              </div>
            </div>
          </li>
        </ol>
      </div>
      <!-- 订单范围下面 -->
      <div class="products">
        <ol class="productWraps">
          <li><span>&nbsp;&nbsp;&nbsp;&nbsp;订单信息</span></li>
          <li>
            <span>联系人</span>
          </li>
          <li>
            <span>金额</span>
          </li>
          <li>
            <span>支付方式</span>
          </li>
          <li>
            <span>状态</span>
          </li>
          <li>
            <span>操作</span>
          </li>
        </ol>
      </div>
      <!-- 订单范围结束 -->
      <div class="empty" v-if="list.length === 0">
        <div class="empty-list">
          <img src="./images/pic_empty.png" alt="" />
          <span>暂无数据</span>
        </div>
      </div>
      <div class="commodity-wrap" v-for="(item, index) in list" :key="index">
        <div class="commodity-content">
          <div class="commodity-list">
            <div class="commodity-img-left">
              <dl>
                <dd>
                  <p>订单编号：{{ item.erpSaleOrderId || " --" }}</p>
                  <p>
                    下单时间：{{ item.orderAt || " --" }}
                  </p>
                  <p>
                    总品规数: <span>{{ item.orderLines.length }}</span>
                    <span style="color: red; margin-left: 7px;" v-if="item.giftOrderNum">(赠品×{{ item.giftOrderNum }})</span>
                  </p>
                </dd>
              </dl>
            </div>
            <div class="commodity-consignee">
              {{ item.userName ? item.userName : '信息部' }}
            </div>
            <div class="commodity-money">
              <p v-if="item.realPayAmount != null">
                ¥ <span>{{ Number(item.realPayAmount) | NumFormat }}</span>
              </p>
              <p v-else></p>
              <p v-if="item.payType == 'ONLINE'">{{ payStatus[item.payStatus || "WAIT"] }}</p>
              <p v-if="item.payType == 'OFFLINE'">其他方式支付</p>
            </div>
            <div style="width: 88px;font-size: 14px;padding-left: 15px">
              {{ item.payWay || item.payType | payWay }}
                <div class="order-tag" v-if="item.supplierName">{{item.supplierName}}代下单</div>
            </div>
            <div class="commodity-unpaid">
              <p  v-if="item.orderStatus == '01' && item.payWay == 'OFFLINEPAY'">
                线下付款
              </p>
              <p :class="{ active: item.orderStatus === '01' ? '' : 'active' }" v-else>
                  {{ item.orderStatus | statusName }}
              </p>
              <p v-if="item.orderStatus === '01'">
                剩余
                <span :key="item.id">
                  <countdown :value="Math.floor(item.closeTime / 1000) + 1" format="h时m分s秒" @stop="getOrderList" />
                </span>
              </p>
            </div>
            <div class="commodity-handle">
              <!--01:待付款  02:待发货(待收货)  03:待收货(运输中)  04:已完成  05:已取消-->
              <template v-if="item.orderStatus === '01' && item.payWay != 'OFFLINEPAY'">
                <b-button type="is-info is-small" @click="handlePayment(item.orderNumber)">立即付款</b-button>
                <p style="color: #08b258" @click="findAFriendToPay(item.orderNumber, item.realPayAmount)">找朋友付</p>
              </template>
              <p v-if="item.orderStatus === '03'" @click="confirmOrder(item.orderNumber)">确认收货</p>
              <p v-if="item.orderStatus === '04'" @click="againPurchase(item)">再次购买</p>
              <p v-if="item.orderStatus === '05'" @click="againPurchase(item)">重新购买</p>
              <p v-if="item.waybillNo" style="color: #3875f6" @click="emsInfo(item)">查看物流</p>
              <p class="commodity-detail" v-if="item.erpOrderId" @click="handleLink(item.orderNumber)">查看详情</p>
              <p
                v-if="(item.orderStatus === '01' || item.orderStatus === '02') && item.erpOrderType != '6'"
                class="commodity-detail"
                style="color: #969696"
                @click="onCloseOrder(item)">
                取消订单
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 分页按钮 -->
    <div class="paginationPager" style="margin:30px auto;">
      <!--'getdata'消息名，接收到消息对应的事件getDataByPage  -->
      <pager :pageInfo="pageInfo" v-on:getdata="getDataByPage"></pager>
    </div>
    <addCarModal ref="addCarModalRef" :p_obj="p_obj" />
      <!--        发票规则-->
      <Dialog :show="logisticsPop" title="物流信息" width="600px" @close="logisticsPop=false">
          <div class="logisticsHeader displayFlexBetween" v-if="tracesData && tracesData.length > 0">
              <div class="displayFlex">
                  <div class="lh-icon displayFlexCenter">
                      <span class="iconfont icon-dituche car"></span>
                  </div>
                  <div class="lh-title">
                      <span>{{tracesData[0].opeName}}</span>
                      <span>{{tracesData[0].waybillCode}}</span>
                  </div>
              </div>
              <div class="lh-copy" @click="copyInfo(tracesData[0].waybillCode)">
                  复制
              </div>
          </div>
          <div class="logisticsTrack">
              <div v-for="(item, index) in tracesData" :key="index">
                  <trackNode :is-first="index === tracesData.length - 1" :is-newest="item.isActive" :node-data="item"></trackNode>
              </div>
          </div>
          <div class="logisticsClose displayFlexCenter">
              <div class="logisticsClose-btn" @click="logisticsPop = false">关闭</div>
          </div>
      </Dialog>
  </div>
</template>

<script>
import { Countdown } from "@components/countdown";
import { AccountStorage } from "@services/account";
// 分页
import pager from '@/components-v2/pager/index.vue'
import addCarModal from "@/components-v2/addCarModal/addCarModal";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/zh-cn";
import publicMethods from '@mixin/publicMethods'
import Dialog from '@/components-v2/v2-dialog/index'
import TrackNode from "@/components-v2/trackNode.vue";
import it from "vue2-datepicker/locale/es/it";
const accountStorage = new AccountStorage();

const PayStatus = {
  WAIT: "待支付",
  PAYING: "支付中",
  SUCCESS: "支付成功",
  FAIL: "支付失败",
  CLOSED: "已失效",
  REFUNDED: "已退款"
};

export default {
    computed: {
        it() {
            return it
        }
    },
  components: {
      TrackNode,
    Countdown,
    DatePicker,
    pager,
    addCarModal,
      Dialog
  },
  data() {
    return {
      payStatus: PayStatus,
      orderTypeList: [
        { id: 0, name: '全部订单' },
        { id: '01', name: '待支付' },
        { id: '05', name: '已取消' },
      ],
      list: [],
      activeIdx: 0,
      large: "", //搜索输入框值
      total: null,
      current: 1, // 页码
      perPage: 10,
      status: "",
      startTime: "",
      endTime: "",
      time: null,
      pageInfo: {
        total: null,
        pageNum: 1,
        pageSize: 50
      },
      p_obj:{},
        tracesData: [],
        logisticsPop:false
    };
  },
  filters: {
    statusName(type) {
      let statusObj = {
        "01": "待支付",
        "02": "待发货",
        "03": "运输中",
        "04": "已完成",
        "05": "已取消"
      };
      return statusObj[type];
    },
    payWay(type) {
      let statusObj = {
        ONLINE: "线上支付",
        ALIPAY: "支付宝支付",
        WXPAY: "微信支付",
        COD: "货到付款"
      };
      return statusObj[type];
    }
  },
  mixins: [publicMethods],
  created() {
    switch (this.$route.params.status) {
      case "01":
        this.activeIdx = 1;
        this.current = 1;
        this.status = this.$route.params.status;
      case "02":
        this.activeIdx = 1;
        this.current = 1;
        this.status = this.$route.params.status;
      case "03":
        this.activeIdx = 2;
        this.current = 1;
        this.status = this.$route.params.status;
      case "04":
        this.activeIdx = 3;
        this.current = 1;
        this.status = this.$route.params.status;
      case "05":
        this.activeIdx = 4;
        this.current = 1;
        this.status = this.$route.params.status;
      case "0":
        this.activeIdx = 0;
        this.current = 1;
        this.$route.params.status = "";
    }
    this.getOrderList();
  },
  methods: {
      //复制单号
      copyInfo(val){
          // 创建输入框元素
          let oInput = document.createElement("input");
          // 将想要复制的值
          oInput.value = val;
          // 页面底部追加输入框
          document.body.appendChild(oInput);
          // 选中输入框
          oInput.select();
          // 执行浏览器复制命令
          document.execCommand("Copy");
          // 弹出复制成功信息
          this.$buefy.toast.open("已复制成功");
          // 复制后移除输入框
          oInput.remove();
      },
      //物流信息
      async emsInfo(item){
          let url = `/pjyy-deepexi-order-center/api/v2/order/OcOrder/getInfoWallbillByOrder`;
          let params = {
              waybillNo: item.waybillNo,
              type: item.logisticsCompany,
          };
          const result = await this.$getHttpClient().get(url, { params });
          if (result && result.data.code == "200") {
              for (let i in result.data.data){
                  if (i == result.data.data.length - 1){
                      result.data.data[i].isActive = true
                  }else{
                      result.data.data[i].isActive = false
                  }
              }
              this.tracesData = result.data.data
              this.logisticsPop = true
          }
      },
    getDataByPage(pageNum) {
      this.current = pageNum
      this.getOrderList();
    },
    handleClear() {
      this.startTime = "";
      this.endTime = "";
      this.getOrderList();
    },
    disabledDate(time) {
      return time.getTime() > Date.now() - 8.64e6; //设置选择今天以及今天之前的日期
    },
    changeOrder(item) {
      this.activeIdx = item.id;
      this.current = 1;
      this.status = item.id ? item.id : '';
      this.getOrderList();
    },
    handleChange(val) {
      this.current = val;
      this.getOrderList();
    },
    // 订单列表
    getOrderList(callback) {
      let data = {
        page: this.current,
        pageSize: this.perPage,
        name: this.large,
        startTime: this.startTime,
        endTime: this.endTime,
      };
      if (this.status) {
        data.status = this.status;
        delete data.selectStatus;
      } else {
        delete data.status;
        // 全部订单状态   02:待发货 03:待收货 04:已完成  06:退换货 07:发货中
        data.selectStatus = ["02", "03", "04"];
      }
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/orderList`;
      this.$getHttpClient().post(url, data).then(res => {
        this.list = [];
        let { code, data } = res.data;
        if (code == 200) {
          this.list = data.content;
          this.total = data.totalElements;
          this.pageInfo.total = this.total
          this.pageInfo.pageNum = this.current
          this.pageInfo.pageSize = this.perPage
          if (typeof callback === "function") {
            callback()
          }
        }
      });
    },
    // 确定收货
    confirmOrder(orderNumber) {
      let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/updateOrderStatus04?orderNumber=${orderNumber}`;
      this.$getHttpClient().get(url)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "操作成功",
              type: "is-success"
            });
            this.getOrderList();
          }
        });
    },
    // 重新购买
    againPurchase(item) {
      let arr = [];
      item.orderLines.forEach(ele => {
        arr.push(ele.productId);
      });
      let url = `/pjyy-deepexi-order-center/api/v1/cart/againAddGoodsToCartList`;
      let data = {
        orderNumber: item.orderNumber
      };
      this.$getHttpClient().post(url, data)
        .then(res => {
          if (res.data.code === "200") {
            this.$buefy.toast.open({
              message: "操作成功",
              type: "is-success"
            });
            this.getOrderList();
            this.$router.push({
              name: "v2-shopcard"
            });
          } else if(res.data.code == "8501"){
              this.p_obj = res.data.payload;
              this.$refs.addCarModalRef.showModal()
          }
        });
    },
    //新取消订单
   async onCloseOrder(obj) {
      let _this = this;
      const URL = `/pjyy-deepexi-erp-api-center/api/v1/erp/order/queryPreCancelOrderAssociationOrder/${obj.erpOrderId}`;
      const result = await _this.$getHttpClient().get(URL);
      if (result.data.code == "200"){
          if (result.data.data && result.data.data.isShow){
              this.$confirm("如果取消该订单，相关联取消的订单有：" + result.data.data.showMsg + "，是否继续？","提示", {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消'
              }).then(() => {
                  _this.confirmCloseOrder(obj, 'list');
              }).catch(() => {
                  // 失败
              })
          }else{
              this.$confirm("是否确认取消订单？", "提示",{
                  confirmButtonText: '确定',
                  cancelButtonText: '取消'
              }).then(() => {
                  _this.confirmCloseOrder(obj, 'list');
              }).catch(() => {
                  // 失败
              })
          }
      }
    },
    // 取消订单
    updateOrderStatus(orderNumber) {
      let that = this;
      this.$buefy.dialog.confirm({
        message: "确定取消订单吗？",
        confirmText: "确定",
        cancelText: "取消",
        type: "is-info",
        onConfirm: function () {
          let url = `/pjyy-deepexi-order-center/api/v1/order/OcOrder/updateOrderStatus05?orderNumber=${orderNumber}`;
          this.$getHttpClient().get(url)
            .then(res => {
              if (res.data.code === "200") {
                this.$buefy.toast.open("操作成功");
                that.getOrderList();
              }
            });
        }
      });
    },
    // 立即付款
    handlePayment(orderNumber) {
      this.$router.push({
        name: "v2-payment",
        query: {
          orderId: orderNumber,
          type:"order"
        }
      });
    },
    formatDate(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate() + 1;
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "/" + MM + "/" + d + " " + h + ":" + m + ":" + s;
    },
    formatDate1(value) {
      let date = new Date(value);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = null;
      if (MM == 12) {
        d = date.getDate() + 1;
      } else {
        MM = date.getMonth() + 2;
        d = date.getDate();
      }
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return y + "/" + MM + "/" + d + " " + h + ":" + m + ":" + s;
    },
    parserDate(date) {
      let t = Date.parse(date);
      if (!isNaN(t)) {
        return new Date(Date.parse(date.replace(/-/g, "/")));
      }
      return t
    },
    // 下拉框
    handleSelect(val) {
      this.current = 1;
      this.startTime = this.formatDate(val[0])
      this.endTime = this.formatDate1(val[1])
      this.getOrderList();
    },
    // 查看详情
    handleLink(id) {
      this.$router.push({
        name: "v2-orderpage",
        params: {
          orderNumber: id
        }
      });
    },
    // 售后退货 暂时先屏蔽掉
    handleSaleLink(id) {
      this.$router.push({
        name: "salesDetail",
        params: {
          status: "1",
          orderNumber: id
        }
      });
    }
  }
};
</script>

<style src="./order.less" lang="less" scoped>

</style>
